import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

import { ApiActionModifier } from '@sulser-print/constants/api-action-modifiers';
import { ApiAction } from '@sulser-print/constants/api-actions';
import { ApiModule } from '@sulser-print/constants/api-modules/api-modules';
import { PaginatedData } from '@sulser-print/models/paginated-response';
import { Project } from '@sulser-print/models/project/project';
import { ProjectUser } from '@sulser-print/models/project/project-user';
import { ProjectUserType } from '@sulser-print/models/project/project-user-type';
import { SimpleProject } from '@sulser-print/models/project/simple-project';
import { Response } from '@sulser-print/models/response';
import { DataTypeUtils } from '@sulser-print/utils/data-type.utils';

import { RequestService } from '../request.service';

@Injectable({
	providedIn: 'any',
})
export class ProjectsService extends RequestService {
	archiveProject(projectId: Project['id']) {
		return this.httpClient.put<Response<Project>>(`${ApiModule.PROJECTS}/${ApiAction.ARCHIVE}/${projectId}`, {});
	}

	create(data: Project, userTypes: Array<ProjectUserType>, users: Array<ProjectUser>) {
		return this.httpClient.post<Response<Project>>(`${ApiModule.PROJECTS}/${ApiAction.CREATE}`, {
			data: { ...data, type: 'DEFAULT' },
			userTypes,
			users,
		});
	}

	deleteProject(projectId: Project['id']) {
		return this.httpClient.delete(`${ApiModule.PROJECTS}/${ApiAction.DELETE}/${projectId}`);
	}

	doesProjectWithNumberExist(number: string, type: 'DEFAULT' = 'DEFAULT') {
		const parameters = new HttpParams({
			fromObject: { number, type },
		});

		const url = `${ApiModule.PROJECTS}/${ApiAction.EXIST}`;

		return this.httpClient.post<Response<boolean>>(url, null, { params: parameters });
	}

	downloadProject(projectId: Project['id']) {
		return this.httpClient.post(
			`${ApiModule.DIRECTORIES}/${ApiAction.DOWNLOAD}/${ApiModule.PROJECT}/${projectId}`,
			{},
			{ responseType: 'blob' },
		);
	}

	getAll(options: { type: Project['type'] } & Params) {
		const { type, ...rest } = options;
		let parameters = new HttpParams({
			fromObject: { type },
		});

		for (const key of Object.keys(rest)) {
			if (!DataTypeUtils.isNullOrUndefined(rest[key])) {
				parameters = parameters.append(key, rest[key]);
			}
		}

		return this.httpClient.get<Response<PaginatedData<Project>>>(`${ApiModule.PROJECTS}/${ApiAction.READ}`, {
			params: parameters,
		});
	}

	getAllSimple(options: Params = {}) {
		let parameters = new HttpParams();

		for (const key of Object.keys(options)) {
			if (!DataTypeUtils.isNullOrUndefined(options[key])) {
				parameters = parameters.append(key, options[key]);
			}
		}

		return this.httpClient.get<Response<PaginatedData<SimpleProject>>>(
			`${ApiModule.PROJECTS}/${ApiAction.READ}/${ApiActionModifier.SIMPLE}`,
			{ params: parameters },
		);
	}

	getById(projectId: Project['id']) {
		return this.httpClient.get<Response<Project>>(`${ApiModule.PROJECTS}/${ApiAction.READ}/${projectId}`);
	}

	getDetailsById(projectId: Project['id']) {
		return this.httpClient.get<Response<Project>>(`${ApiModule.PROJECTS}/${ApiAction.READ}/${projectId}/${ApiActionModifier.DETAILS}`);
	}

	hardDeleteProjects(projectIds: Array<Project['id']>) {
		return this.httpClient.delete(`${ApiModule.PROJECTS}/${ApiAction.DELETE_ALL}`, { body: projectIds });
	}

	hideProject(projectId: Project['id']) {
		return this.httpClient.put<Response<Project>>(`${ApiModule.PROJECTS}/${ApiAction.HIDE}/${projectId}`, {});
	}

	restoreProjects(projectIds: Array<Project['id']>) {
		return this.httpClient.delete<Response<null>>(`${ApiModule.PROJECTS}/${ApiAction.RESTORE_ALL}`, { body: projectIds });
	}

	showProject(projectId: Project['id']) {
		return this.httpClient.put<Response<Project>>(`${ApiModule.PROJECTS}/${ApiAction.SHOW}/${projectId}`, {});
	}

	updateProject(project: { type: string } | Project) {
		return this.httpClient.put<Response<Project>>(`${ApiModule.PROJECTS}/${ApiAction.UPDATE}`, project);
		// return this.requestService
		// 	.request<Project>({
		// 		url: `${this.apiUri}/update`,
		// 		method: RequestMethod.PUT,
		// 		options: { body: project }
		// 	})
	}
}
